import { toast } from 'vue-sonner'

interface UseNotificationConfig {
  description?: string
}

export function useNotification() {
  function notifySuccess(title: string, config?: UseNotificationConfig) {
    toast.success(title, { description: config?.description })
  }

  function notifyError(title: string, config?: UseNotificationConfig) {
    toast.error(title, { description: config?.description })
  }

  function notifyInfo(title: string, config?: UseNotificationConfig) {
    toast.info(title, { description: config?.description })
  }

  function notifyWarning(title: string, config?: UseNotificationConfig) {
    toast.warning(title, { description: config?.description })
  }

  return {
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning,
  }
}
